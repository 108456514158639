import moment from 'moment-timezone';

// Parses, prunes and flattens nested event occurrences
export const parseEvents = (events, currentUtcTime, timezone) => {
  const formattedEvents = [];

  // Flattening the Events & sorting them by start date
  const flattendEvents = events
    .reduce(
      (array, event) =>
        array.concat(
          event.occurrences.map((occurence) => ({
            appointment_id: event.appointment_id,
            title: event.title,
            color: event.color,
            isAllDay: event.is_all_day,
            location: event.location,
            facility_name: event.facility_name,
            notes: event.notes,
            startDateTime: moment.utc(
              event.is_all_day
                ? moment.tz(occurence.start_date_time, timezone)
                : occurence.start_date_time,
            ),
            endDateTime: moment.utc(
              event.is_all_day
                ? moment.tz(occurence.end_date_time, timezone)
                : occurence.end_date_time,
            ),
            eventDay: moment.tz(occurence.start_date_time, timezone).format('l'),
          })),
        ),
      [],
    )
    .sort((a, b) => (a.startDateTime > b.startDateTime ? 1 : -1));

  flattendEvents.forEach((event, idx) => {
    let { endDateTime } = event;
    const eventData = {
      location: event.location,
      facility_name: event.facility_name,
      label: event.title,
      color: `#${event.color}`,
    };

    // Set no end time occurrences to end at start of next appt or end of day.
    const apptHasNoEndTime = event.startDateTime.isSame(event.endDateTime);
    if (apptHasNoEndTime) {
      const nextApptStart = flattendEvents?.[idx + 1]?.startDateTime;
      // Get End Of Day based on org timezone then convert to UTC to match the events timezone
      const endOfDay = moment.utc(moment.tz(event.startDateTime, timezone).endOf('day'));

      if (nextApptStart && nextApptStart.isBefore(endOfDay)) {
        endDateTime = nextApptStart;
      } else {
        endDateTime = endOfDay;
      }
    }

    // Set event data and occurrence data together
    const eventOccurrence = {
      ...eventData,
      start: event.startDateTime,
      end: endDateTime,
      // Flag to not show end time
      hasEndTime: !event.isAllDay && !apptHasNoEndTime,
      // Flag for special labeling for "All Day" events
      isAllDay: event.isAllDay,
    };

    // Include eventOcurrence if its happening now or in the future
    if (currentUtcTime.isBefore(endDateTime)) {
      if (eventOccurrence.isAllDay) {
        // All day events always first
        formattedEvents.unshift(eventOccurrence);
      } else {
        formattedEvents.push(eventOccurrence);
      }
    }
  });

  return formattedEvents;
};

export const getBrowserInfo = (userAgent) => {
  let browserInfo = '';
  let temporaryMatch;
  let match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(match[1])) {
    temporaryMatch = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    browserInfo = `IE ${temporaryMatch[1] || ''}`;
  }
  if (match[1] === 'Chrome') {
    temporaryMatch = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
    if (temporaryMatch != null)
      browserInfo = temporaryMatch.slice(1).join(' ').replace('OPR', 'Opera');
  }
  match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?'];
  temporaryMatch = userAgent.match(/version\/(\d+)/i);
  if (temporaryMatch != null) match.splice(1, 1, temporaryMatch[1]);
  browserInfo = match.join(' ');

  const [browserType, browserVersion] = browserInfo.split(' ');

  return { browserType, browserVersion };
};
