import styled from '@emotion/styled';
import React from 'react';
import PropTypes from 'prop-types';
import { getBrowserInfo } from '../utils/index';

const { browserType, browserVersion } = getBrowserInfo(navigator.userAgent);

const DetailsText = styled.span({
  fontSize: '24px',
  fontWeight: 400,
});

const ImageContainer = styled.div(({ isPortrait = false }) => {
  if (isPortrait) return undefined;
  return {
    alignSelf: 'center',
    borderRadius: 100,
    height: 200,
    minHeight: 200,
    minWidth: 200,
    overflow: 'hidden',
    width: 200,
  };
});

const MainContainer = styled.div(({ isPortrait = false }) => {
  const sharedStyles = {
    display: 'flex',
    columnGap: 50,
    overflow: 'hidden',
    padding: 40,
    width: '100%',
    ...(!(browserType === 'Chrome' && Number(browserVersion) >= 80)
      ? { backgroundColor: 'rgba(3,3,3,0.6)' }
      : {
          backgroundColor: 'rgba(38, 34, 42, 0.7)',
          backdropFilter: 'blur(8px)',
        }),
  };
  if (isPortrait) {
    return {
      ...sharedStyles,
      flex: 1,
      flexDirection: 'column',
      margin: 10,
      textAlign: 'center',
    };
  }
  return sharedStyles;
});

const ImagePortrait = styled.img(({ isPortrait = false }) => {
  if (isPortrait) return { height: 200, width: 200 };
  return {
    borderRadius: 100,
    maxHeight: 200,
    maxWidth: '100%',
    minWidth: 176,
    objectFit: 'contain',
  };
});

const TextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const TitleText = styled.h1({
  fontSize: '24px',
  fontWeight: 700,
  marginBottom: '8px',
});

const Announcement = ({ title, details, image, centerImage }) => (
  <MainContainer isPortrait={centerImage}>
    {image && (
      <ImageContainer isPortrait={centerImage}>
        <ImagePortrait src={image} alt="announcement" isPortrait={centerImage} />
      </ImageContainer>
    )}
    <TextContainer>
      {title && <TitleText>{title}</TitleText>}
      {details && <DetailsText>{details}</DetailsText>}
    </TextContainer>
  </MainContainer>
);

Announcement.defaultProps = {
  centerImage: false,
  title: undefined,
  details: undefined,
  image: undefined,
};

Announcement.propTypes = {
  title: PropTypes.string,
  details: PropTypes.string,
  image: PropTypes.string,
  centerImage: PropTypes.bool,
};

export default Announcement;
