/* eslint-disable camelcase */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment-timezone';

import { parseEvents } from '../utils';
import EventPage from '../components/events/eventPage';
import LoadingState from '../components/loading';
import ErrorState from '../components/error';

const pathArray = window.location.pathname.split('/');
const [host, uuid] = atob(pathArray[pathArray.length - 1]).split(' ');
const isLocal = process.env.REACT_APP_ENVIRONMENT_NAME === 'local';
const settingsUrl = isLocal
  ? `http://${process.env.REACT_APP_API_HOST}/api/v1/displays/settings/${uuid}`
  : `https://${host}/api/v1/displays/settings/${uuid}`;
const appointmentsUrl = isLocal
  ? `http://${process.env.REACT_APP_API_HOST}/api/v1/displays/appointments/${uuid}`
  : `https://${host}/api/v1/displays/appointments/${uuid}`;

const BASE_FETCH_INTERVAL_AFTER_ERROR = 15000; // 15 sec
const SETTINGS_FETCH_INTERVAL = 300000; // 5 min
const APPOINTMENTS_FETCH_INTERVAL = 300000; // 5 min

const Main = () => {
  // These are refs to escape the variable value closure for setTimeout & setInterval
  const appointments = useRef({});
  const selectedIndexRef = useRef(0);
  const loopIntervalRef = useRef();

  const [settings, setSettings] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isValidUrl, setIsValidUrl] = useState(false);

  // we stop attempting to fetch after a certain number of failed fetches
  const [settingsFetchCapHit, setSettingsFetchCapHit] = useState(false);
  const [appointmentsFetchCapHit, setAppointmentsFetchCapHit] = useState(false);

  /*
    If a fetch fails, we'll continue to show the cached response from the last fetch, but we
    keep track of the number of failures. If we ever fail to fetch 7 times in a row (meaning
    the calendar is ~30 minutes out-of-date) we show an error screen indicating the user should
    contact support. These counters are reset to 0 on a successful fetch.

    We're using variables here rather than the useState hook, because these counters are being updated
    within the closure of our setTimeout functions, and thus we can't increment them because the next
    function call will use the value from the closure of the function that called it rather than the
    parent state. That tripped me up for a while. More info here:
    https://stackoverflow.com/questions/54069253/usestate-set-method-not-reflecting-change-immediately
  */
  let settingsFetchFailCount = 0;
  let appointmentsFetchFailCount = 0;
  let setTimeoutForNextFetchAfterError;

  const fetchSettings = async () => {
    try {
      const response = await fetch(settingsUrl);
      if (response.status !== 200) throw new Error(response.statusText);
      const responseJson = await response.json();
      settingsFetchFailCount = 0;
      if (loopIntervalRef?.current) {
        clearInterval(loopIntervalRef.current);
      }
      setSettings(responseJson);
      setTimeout(fetchSettings, SETTINGS_FETCH_INTERVAL);
      return !!responseJson; // this is so we know at least one fetch succeeded, so this is a valid URL
    } catch (error) {
      setTimeoutForNextFetchAfterError('settings');
      console.error(error);
      return false;
    }
  };

  // TODO Rework this to use setInterval and use clearInterval
  const fetchAppointments = async (calendarIndex) => {
    try {
      const apptEndpoint =
        calendarIndex !== undefined
          ? `${appointmentsUrl}?cal_key=${calendarIndex}`
          : appointmentsUrl;
      const response = await fetch(apptEndpoint);
      const responseJson = await response.json();
      if (response.status !== 200) {
        throw new Error(responseJson);
      }
      appointmentsFetchFailCount = 0;

      // Update the "appointments" variable with the new appointment data
      if (calendarIndex !== undefined) {
        const tmp = {};
        tmp[calendarIndex] = responseJson;
        appointments.current = { ...appointments.current, ...tmp };
      } else {
        appointments.current = { 0: responseJson };
        setSelectedIndex(0);
      }
      setTimeout(() => {
        fetchAppointments(calendarIndex);
      }, APPOINTMENTS_FETCH_INTERVAL);
      return !!responseJson; // this is so we know at least one fetch succeeded, so this is a valid URL
    } catch (error) {
      // If we get a 'No calendar found' it means the settings were updated and the calendar was removed.
      // No need to try and refetch for a key that does not exist. Remove the calendar from the appointments
      if (!error?.message?.includes('No calendar found for key')) {
        delete appointments.current[calendarIndex ?? 0];
        return false;
      }
      if (calendarIndex) {
        setTimeoutForNextFetchAfterError('appointments', calendarIndex);
      } else {
        setTimeoutForNextFetchAfterError('appointments');
      }
      console.error(error);
      return false;
    }
  };

  const transitionLoop = async () => {
    // If we don't have data for the calendar, start a fetchAppointment loop
    if (!appointments.current[selectedIndexRef.current]) {
      await fetchAppointments(selectedIndexRef.current);
    }

    // Update the selected title and selected index
    setSelectedIndex((prevSelected) => {
      if (prevSelected >= Object.keys(settings.multiple_calendars_keys).length - 1) {
        return 0;
      }
      return prevSelected + 1;
    });
    setSelectedTitle(settings.multiple_calendars_keys[selectedIndexRef.current]);
  };

  setTimeoutForNextFetchAfterError = (typeOfFetch, calendarIndex) => {
    /*
      When a fetch fails we do an exponential backoff where we try to refetch after 30 seconds.
      If that next fetch fails, we’ll try again after 1 minute, then 2, 4, 8, and 16.
      After 6 failed fetches, we stop trying to refetch and show the error screen.
    */
    const newFetchFailCount =
      typeOfFetch === 'settings' ? settingsFetchFailCount + 1 : appointmentsFetchFailCount + 1;
    if (newFetchFailCount === 7) {
      // if we've failed 7 fetches in a row, show the error screen
      if (typeOfFetch === 'settings') {
        setSettingsFetchCapHit(true);
      } else {
        setAppointmentsFetchCapHit(true);
      }
    } else {
      const newErrorTimeout = BASE_FETCH_INTERVAL_AFTER_ERROR * 2 ** newFetchFailCount;
      // set the timeout based on the number of failed fetches
      if (typeOfFetch === 'settings') {
        settingsFetchFailCount = newFetchFailCount;
        setTimeout(fetchSettings, newErrorTimeout);
      } else {
        appointmentsFetchFailCount = newFetchFailCount;
        if (calendarIndex) {
          setTimeout(fetchAppointments(calendarIndex), newErrorTimeout);
        } else {
          setTimeout(fetchAppointments, newErrorTimeout);
        }
      }
    }
  };

  // useEffect for starting calendar fetching and switching for multiCalendar.
  // Resets if the "settings" variable changes.
  useEffect(async () => {
    if (settings) {
      // Multi Calendar display
      if (settings.multiple_calendars_enabled) {
        // Have to call transition loop first since setInterval does not immediately run
        transitionLoop();
        loopIntervalRef.current = setInterval(
          transitionLoop,
          (settings?.transition_speed ?? 15) * 1000,
        );
        return () => clearInterval(loopIntervalRef.current);
      }

      // Single Calendar display
      if (settings.show_label_on_display) {
        setSelectedTitle(settings.label);
      }

      // If we don't have data for the calendar, start a fetchAppointment loop
      if (!appointments.current[0]) {
        const weHaveAppointments = await fetchAppointments();
        setIsValidUrl(weHaveAppointments);
      }
    }
    return null;
  }, [settings]);

  useEffect(() => {
    selectedIndexRef.current = selectedIndex;
  }, [selectedIndex]);

  // Default useEffect to start settings and appointment loops
  useEffect(async () => {
    const weHaveSettings = await fetchSettings();
    setIsLoading(false);
    setIsValidUrl(weHaveSettings);
  }, []);

  const events = useMemo(() => {
    if (settings) {
      // Flatten appointments structure into list of events
      return parseEvents(
        appointments.current[selectedIndexRef.current] ?? [],
        moment.utc(),
        settings.time_zone,
      );
    }
    return [];
  }, [selectedIndex, appointments.current, settings]);

  // we show a special error screen if we assume the fetches failed due to a bad URL
  if (!isLoading && !isValidUrl) {
    return (
      <ErrorState
        heading="Display not found"
        errorMessages={[
          "We couldn't find a Teamworks display at this URL.",
          'Check that the URL matches the one in the Teamworks Displays settings.',
        ]}
      />
    );
  }

  // if we've hit the cap for the max number of failed fetches on either endpoint,
  // show an error message encouraging the user to contact support
  if (settingsFetchCapHit || appointmentsFetchCapHit) {
    return (
      <ErrorState
        heading="Something went wrong"
        errorMessages={[
          'There was an issue fetching updates to the calendar. Please check your internet connection.',
        ]}
        showContact
      />
    );
  }

  if (isLoading) {
    return <LoadingState heading="Please wait!" />;
  }

  return <EventPage events={events} settings={settings} title={selectedTitle} />;
};

export default Main;
