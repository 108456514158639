import { datadogRum } from '@datadog/browser-rum';
import PropTypes from 'prop-types';
import React from 'react';

import ErrorState from './components/error';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    datadogRum.addError(error, errorInfo);
  }

  render() {
    const { error, hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <ErrorState errorMessages={[error.message]} heading="Something went wrong" showContact />
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
