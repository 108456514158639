import styled from '@emotion/styled';
import React from 'react';
import PropTypes from 'prop-types';
import twDefaultBackground from '../assets/twDefaultBg.jpeg';

const spinnerColor = '#FFFFFF';

const BackgroundImage = styled.div({
  background: `url("${twDefaultBackground}") left bottom no-repeat`,
  backgroundSize: 'cover',
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
});

const MainContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  overflow: 'hidden',
});

const ItemContainer = styled.div({
  flex: 0,
  marginTop: '3rem',
});

const Spinner = styled.svg({
  fontSize: 60,
  height: '1em',
  width: '1em',
});

const HeadingText = styled.p({
  fontSize: '4rem',
  fontWeight: 600,
});

const LoadingState = ({ heading }) => (
  <BackgroundImage>
    <MainContainer>
      <ItemContainer>
        <Spinner
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          aria-label="loading-spinner"
        >
          <defs>
            <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
              <stop stopColor={spinnerColor} stopOpacity="0" offset="0%" />
              <stop stopColor={spinnerColor} stopOpacity=".631" offset="63.146%" />
              <stop stopColor={spinnerColor} offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)">
              <path
                d="M36 18c0-9.94-8.06-18-18-18"
                id="Oval-2"
                stroke={spinnerColor}
                strokeWidth="2"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.9s"
                  repeatCount="indefinite"
                />
              </path>
              <circle fill="#fff" cx="36" cy="18" r={1}>
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.9s"
                  repeatCount="indefinite"
                />
              </circle>
            </g>
          </g>
        </Spinner>
      </ItemContainer>
      <ItemContainer>
        <HeadingText>{heading}</HeadingText>
      </ItemContainer>
    </MainContainer>
  </BackgroundImage>
);

LoadingState.propTypes = {
  heading: PropTypes.string.isRequired,
};

export default LoadingState;
