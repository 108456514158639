import styled from '@emotion/styled';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import shortid from 'shortid';

import { EventCard, EmptyCard } from './eventCard';

const TitleDiv = styled.div(({ firstDate }) => {
  const sharedStyles = {
    '@keyframes fadeIn': {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    animation: 'fadeIn 1s linear',
    fontSize: '32px',
    color: 'rgba(255, 255, 255, 0.6)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: '1rem',
    marginLeft: '3rem',
  };
  if (firstDate) {
    return {
      ...sharedStyles,
      marginTop: 0,
    };
  }
  return sharedStyles;
});

// Formats date and if the date is in the past, it's a multi-day all day event.
// We don't want to return past days so we return today
const formatDateString = (dateString, tz) => {
  if (moment(dateString).tz(tz).isBefore(moment().tz(tz), 'day')) {
    return moment().tz(tz).format('ddd MMM D, YYYY');
  }

  return moment(dateString).tz(tz).format('ddd MMM D, YYYY');
};

// Takes a list of events, outputs array of unique dates from the set
const getUniqueDates = (events, tz) =>
  events.reduce((datesArray, event) => {
    // If event is all day, check for multi-day and add tomorrow's date string if needed
    if (event.isAllDay && event.end) {
      const startTime = moment(event.start);
      const endTime = moment(event.end);
      const days = endTime.diff(startTime, 'day') + 1;
      if (days > 1) {
        const tomorrow = moment().tz(tz).add(1, 'day');
        const tomorrowString = formatDateString(tomorrow, tz);
        if (!datesArray.includes(tomorrowString)) {
          datesArray.push(tomorrowString);
        }
      }
    }
    const dateString = formatDateString(event.start, tz);
    if (!datesArray.includes(dateString)) {
      datesArray.push(dateString);
    }
    return datesArray;
  }, []);

const sortByLabel = (a, b) => a.label.localeCompare(b.label);

const sortDaysEvents = (events) => {
  const allDayEvents = [];
  const normalEvents = [];

  events.forEach((event) => {
    if (event.isAllDay) {
      allDayEvents.push(event);
    } else {
      normalEvents.push(event);
    }
  });
  return [
    ...allDayEvents.sort((a, b) => {
      // All day events with the same start and end time should be sorted by label
      if (a.start.valueOf() === b.start.valueOf() && a.end.valueOf() === b.end.valueOf()) {
        return sortByLabel(a, b);
      }
      return a.start.valueOf() - b.start.valueOf() || a.end.valueOf() - b.end.valueOf();
    }),
    ...normalEvents.sort(
      (a, b) => a.start.valueOf() - b.start.valueOf() || a.end.valueOf() - b.end.valueOf(),
    ),
  ];
};

// Takes a list of events and outputs list of events by date, orders events and groups by ascending
// Group events by start date for non-all-day events and duplicate events if all day events span multiple days
const groupEventsByDate = (events, tz) =>
  getUniqueDates(events, tz)
    .map((date) => ({
      events: sortDaysEvents(
        events.filter(
          (event) =>
            // add event to filter if formatted date string matches date string or if the date is within the range of an all day event
            formatDateString(event.start, tz) === date ||
            (event.isAllDay &&
              event.end &&
              moment(event.start).isBefore(moment(date), 'day') &&
              moment(event.end).isAfter(moment(date), 'day')),
        ),
      ),
      title: date,
    }))
    .sort((a, b) => {
      if (moment(a.title).isBefore(moment(b.title))) {
        return -1;
      }
      return 1;
    });

const renderEvents = (groupedEvents, timezone, timeFormat) => (
  <>
    {groupedEvents.map((dateSet, idx) => (
      <div key={shortid.generate()}>
        <TitleDiv key={dateSet.title} firstDate={idx === 0}>
          {dateSet.title === moment().tz(timezone).format('ddd MMM D, YYYY')
            ? 'Today'
            : dateSet.title}
        </TitleDiv>
        {dateSet.events.map((event) => {
          const isOngoing = moment().diff(moment(event.start)) >= 0;
          return (
            <EventCard
              dateSection={dateSet.title}
              event={event}
              timezone={timezone}
              timeFormat={timeFormat}
              isOngoing={isOngoing}
              key={shortid.generate()}
            />
          );
        })}
      </div>
    ))}
  </>
);

const EventSet = ({ events, timezone, timeFormat }) => {
  if (events.length) {
    const groupedEvents = groupEventsByDate(events, timezone);
    return renderEvents(groupedEvents, timezone, timeFormat);
  }
  return (
    <>
      <TitleDiv firstDate>Today</TitleDiv>
      <EmptyCard text="No upcoming events" />
    </>
  );
};

EventSet.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  timezone: PropTypes.string.isRequired,
  timeFormat: PropTypes.string.isRequired,
};

export default EventSet;
