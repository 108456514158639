import styled from '@emotion/styled';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

// Renders all day events, normal events with start and end times.
const renderTimeDiv = (event, timezone, timeFormat, dateSection) => {
  const { start, end, isAllDay, hasEndTime } = event;
  if (isAllDay) {
    if (!end) {
      // Single day all day event
      return (
        <TimeDiv>
          <CardTopLine>ALL DAY</CardTopLine>
        </TimeDiv>
      );
    }
    // If startTimeSting and endTimeString exceed 1 day, show day x of y
    const startTime = moment(start);
    const endTime = moment(end);
    const days = endTime.diff(startTime, 'day') + 1;

    if (days > 1) {
      let currentDay = moment().diff(startTime, 'day') + 1;
      if (dateSection !== 'Today') {
        currentDay = moment(dateSection).diff(startTime, 'day') + 1;
      }
      // Multi day all day event
      return (
        <TimeDiv>
          <CardTopLine>ALL DAY</CardTopLine>
          <CardBottomLine>
            Day {currentDay}/{days}
          </CardBottomLine>
        </TimeDiv>
      );
    }
    // Multi day difference is <1 day, show as single day all day event
    return (
      <TimeDiv>
        <CardTopLine>ALL DAY</CardTopLine>
      </TimeDiv>
    );
  }
  // Non all day events
  return (
    <TimeDiv>
      <CardTopLine>{start.tz(timezone).format(timeFormat)}</CardTopLine>
      {hasEndTime && end && <CardBottomLine>{end.tz(timezone).format(timeFormat)}</CardBottomLine>}
    </TimeDiv>
  );
};

const EventCard = ({ dateSection, event, timezone, timeFormat, isOngoing = false }) => (
  <CardContainer highlighted={isOngoing}>
    {renderTimeDiv(event, timezone, timeFormat, dateSection)}
    <EventDiv>
      <CardTopLine>{event.label}</CardTopLine>
      <CardBottomLine>{!!event.facility_name ? event.facility_name : event.location}</CardBottomLine>
    </EventDiv>
  </CardContainer>
);

const EmptyCard = ({ text }) => (
  <CardContainer>
    <CardTopLine>
      <EmptyCardText>{text}</EmptyCardText>
    </CardTopLine>
  </CardContainer>
);

const CardContainer = styled.div(({ highlighted = false }) => {
  const sharedStyles = {
    '@keyframes fadeIn': {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    animation: 'fadeIn 1s linear',
    columnGap: '1rem',
    display: 'flex',
    flexDirection: 'row',
    padding: '0.75rem',
  };
  if (highlighted) {
    return {
      ...sharedStyles,
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderLeft: '8px solid #fff',
      paddingLeft: '2.5rem',
      borderLeftColor: '#fff',
    };
  }
  return {
    ...sharedStyles,
    paddingLeft: '3rem',
  };
});

const CardBottomLine = styled.div({
  color: 'rgba(255, 255, 255, 0.6)',
  display: 'flex',
  fontSize: '18px',
});

const CardTopLine = styled.div({
  color: '#fff',
  display: 'flex',
  fontSize: '24px',
  fontWeight: 600,
  marginBottom: '-0.5rem',
});

const EmptyCardText = styled.span({
  paddingLeft: '3rem',
});

const EventDiv = styled.div({
  flex: 5,
});

const TimeDiv = styled.div({
  flex: 1,
  minWidth: '110px',
});

EventCard.defaultProps = {
  isOngoing: false,
};

EventCard.propTypes = {
  dateSection: PropTypes.string.isRequired,
  event: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  timeFormat: PropTypes.string.isRequired,
  isOngoing: PropTypes.bool,
};

EmptyCard.propTypes = {
  text: PropTypes.string.isRequired,
};
export { EventCard, EmptyCard };
