/* eslint-disable camelcase */
import styled from '@emotion/styled';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import Announcement from '../announcement';
import EventSet from './eventSet';
import Clock from '../clock';
import teamworksLogo from '../../assets/teamworksLogo.png';
import twDefaultBackground from '../../assets/twDefaultBg.jpeg';
import { getBrowserInfo } from '../../utils/index';

const { browserType, browserVersion } = getBrowserInfo(navigator.userAgent);

const AnnouncementsContainer = styled.div(({ isPortrait = false }) => {
  const sharedStyles = {
    display: 'flex',
    rowGap: 40,
    marginTop: 20,
  };
  if (isPortrait) {
    return {
      ...sharedStyles,
      order: 1,
      flexDirection: 'row',
      justifyContent: 'space-around',
      margin: 36,
    };
  }
  return {
    ...sharedStyles,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  };
});

const BackgroundImageDiv = styled.div(({ backgroundImage = twDefaultBackground, isPortrait }) => ({
  background: `url("${backgroundImage}") center center no-repeat`,
  backgroundSize: 'cover',
  display: 'flex',
  flexDirection: isPortrait ? 'column' : 'row',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const ClockColumn = styled.div(({ isPortrait }) => {
  const sharedStyles = {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 50px',
  };
  if (isPortrait) {
    return {
      ...sharedStyles,
      flex: 1,
    };
  }
  return {
    ...sharedStyles,
    flex: 3,
    overflow: 'auto',
  };
});

const ClockContainer = styled.div({
  display: 'flex',
  flex: 1,
});

const EventColumn = styled.div(({ isPortrait }) => ({
  display: 'flex',
  flex: 5,
  flexDirection: 'column',
  padding: '2rem 0rem 0rem 0rem',
  margin: isPortrait ? '0rem 4rem 0rem 4rem' : 'initial',
  overflow: 'auto',
  ...(!(browserType === 'Chrome' && Number(browserVersion) >= 80)
    ? { backgroundColor: 'rgba(3,3,3,0.6)' }
    : {
        backgroundColor: 'rgba(38, 34, 42, 0.7)',
        backdropFilter: 'blur(8px)',
      }),
}));

const EventContainer = styled.div({
  flex: 1,
});

const Footer = styled.div({
  position: 'absolute',
  bottom: 0,
  height: '80px',
  width: '100%',
  backgroundColor: '#121212',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const HeaderContainer = styled.div(({ isPortrait }) => ({
  alignItems: isPortrait ? 'center' : 'flex-start',
  display: 'flex',
  flexDirection: 'row',
}));

const LabelStyle = styled.div({
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  animation: 'fadeIn 1s linear',
  fontSize: 32,
  fontWeight: 'bold',
  margin: '0rem 0rem 2rem 3rem',
});

const LogoContainer = styled.div({
  width: 130,
});

const LogoStyle = styled.img({
  maxHeight: 200, // we don't want the logo to bleed into announcements
  objectFit: 'contain', // this will enforce proportions with logoContainer:width as the decider
  width: '100%',
});

const MainContainer = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
});

const TWLogoContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const TWLogoStyle = styled.img({
  width: '300px',
  height: '40px',
});

const renderAnnouncement = (widget, isPortrait, announcementCount) => (
  <Announcement
    key={widget.position}
    title={widget.title}
    details={widget.details}
    image={widget.image}
    centerImage={isPortrait && announcementCount > 1}
  />
);

const EventPage = ({ events, settings, title }) => {
  const announcements = settings?.widget_settings.filter((widget) => {
    const startDatetime = widget?.start_datetime ? moment(widget?.start_datetime) : undefined;
    const endDatetime = widget?.end_datetime ? moment(widget?.end_datetime) : undefined;
    // No start or end date set, announcement will always be shown
    if (!startDatetime && !endDatetime) {
      return widget;
    }

    // Announcement has a defined date range
    if (startDatetime && endDatetime) {
      if (moment().isBetween(startDatetime, endDatetime)) {
        return widget;
      }
      // Now is outside of date range - do not show announcement
      return null;
    }

    // Announcement only has a startDate or only an endDate
    if (
      (startDatetime && moment().isAfter(startDatetime)) ||
      (endDatetime && moment().isBefore(endDatetime))
    ) {
      return widget;
    }
    return null;
  });
  const announcementCount = announcements?.length;
  const isPortrait = settings?.orientation === 'portrait';
  const timeFormat = settings?.time_format === '24h' ? 'H:mm' : 'h:mm A';

  return (
    <MainContainer>
      <BackgroundImageDiv
        backgroundImage={settings.background_image || twDefaultBackground}
        isPortrait={isPortrait}
      >
        <ClockColumn isPortrait={isPortrait}>
          <HeaderContainer>
            <ClockContainer>
              <Clock timezone={settings.time_zone} timeFormat={timeFormat} />
            </ClockContainer>
            {settings?.logo_url && (
              <LogoContainer>
                <LogoStyle alt="Custom Logo" src={settings?.logo_url} data-testid="customLogo" />
              </LogoContainer>
            )}
          </HeaderContainer>
          {!isPortrait && (
            <AnnouncementsContainer>
              {announcements?.map((widget) =>
                renderAnnouncement(widget, isPortrait, announcementCount),
              )}
            </AnnouncementsContainer>
          )}
        </ClockColumn>
        {isPortrait && (
          <AnnouncementsContainer isPortrait>
            {announcements?.map((widget) =>
              renderAnnouncement(widget, isPortrait, announcementCount),
            )}
          </AnnouncementsContainer>
        )}
        <EventColumn isPortrait={isPortrait}>
          {title && <LabelStyle key={title}>{title}</LabelStyle>}
          <EventContainer>
            <EventSet events={events} timezone={settings.time_zone} timeFormat={timeFormat} />
          </EventContainer>
        </EventColumn>
      </BackgroundImageDiv>
      <Footer>
        <TWLogoContainer>
          <TWLogoStyle alt="TW Logo" src={teamworksLogo} />
        </TWLogoContainer>
      </Footer>
    </MainContainer>
  );
};

EventPage.defaultProps = {
  title: null,
};

EventPage.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  settings: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default EventPage;
