import styled from '@emotion/styled';
import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import twDefaultBackground from '../assets/twDefaultBg.jpeg';
import triangleExclamation from '../assets/triangleExclamation.svg';

const BackgroundImage = styled.div({
  display: 'flex',
  width: '100%',
  height: '100%',
  background: `url("${twDefaultBackground}") left bottom no-repeat`,
  backgroundSize: 'cover',
  overflow: 'hidden',
});

const MainContainer = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  overflow: 'hidden',
});

const ItemContainer = styled.div({
  flex: 0,
  marginTop: '3rem',
});

const HeadingText = styled.p({
  fontSize: '4rem',
  fontWeight: 600,
});

const ErrorMessageText = styled.p({
  marginTop: '3rem',
  fontSize: '2.4rem',
  color: '#d8d8d8',
});

const ErrorState = ({ heading, errorMessages, showContact }) => (
  <BackgroundImage>
    <MainContainer>
      <ItemContainer>
        <img alt="caution icon" src={triangleExclamation} />
      </ItemContainer>
      <ItemContainer>
        <HeadingText>{heading}</HeadingText>
        {errorMessages.map((message) => (
          <ErrorMessageText key={shortid.generate()}>{message}</ErrorMessageText>
        ))}
        {showContact && (
          <ErrorMessageText>
            If the issue persists, contact Teamworks support at{' '}
            <a href="emailto:support@teamworks.com">support@teamworks.com</a>
          </ErrorMessageText>
        )}
      </ItemContainer>
    </MainContainer>
  </BackgroundImage>
);

ErrorState.defaultProps = {
  showContact: false,
};

ErrorState.propTypes = {
  heading: PropTypes.string.isRequired,
  errorMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
  showContact: PropTypes.bool,
};

export default ErrorState;
