import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const ClockContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const DateText = styled.span({
  fontSize: '32px',
});

const TimeText = styled.span({
  fontSize: '32px',
  fontWeight: 'bold',
});

const CLOCK_INTERVAL = 10000; // 10 seconds

// Clock state hook
const useClock = () => {
  const [time, setTime] = useState(moment.utc());
  // Set up inital mount with interval
  useEffect(() => {
    const interval = setInterval(() => setTime(moment.utc()), CLOCK_INTERVAL);
    return () => clearInterval(interval);
  }, []);
  return time;
};

const Clock = ({ timezone, timeFormat }) => {
  const time = useClock();
  return (
    <ClockContainer>
      <DateText>{time.tz(timezone).format('ddd MMM Do, YYYY')}</DateText>
      <TimeText>{time.tz(timezone).format(timeFormat)}</TimeText>
    </ClockContainer>
  );
};

Clock.propTypes = {
  timezone: PropTypes.string.isRequired,
  timeFormat: PropTypes.string.isRequired,
};

export default Clock;
