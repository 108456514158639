import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

// The datadogRum SDK with tracing enabled doesn't play well with Cypress. It ends
// up injecting duplicate headers as an array which cannot be parsed properly.

if (process.env.REACT_APP_DD_RUM_ENABLED) {
  datadogRum.init({
    allowedTracingUrls: [/https:\/\/.*\.teamworksapp\.com\/displays/],
    applicationId: process.env.REACT_APP_DD_RUM_APP_ID ?? '',
    clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN ?? '',
    defaultPrivacyLevel:'allow',
    env: process.env.REACT_APP_DD_RUM_ENV,
    sessionReplaySampleRate: Number(process.env.REACT_APP_DD_RUM_SAMPLE_RATE),
    sessionSampleRate: Number(process.env.REACT_APP_DD_RUM_SAMPLE_RATE),
    service: process.env.REACT_APP_DD_RUM_APP_NAME,
    site: 'datadoghq.com',
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    beforeSend: (event) => {
      if (event.type === 'error') {
        return false;
      }
      if (
        event.type === 'resource' &&
        event.resource.status_code &&
        event.resource.status_code >= 500
      ) {
        datadogRum.addError(
          `${event.resource.method} ${event.resource.url} ${event.resource.status_code}`,
        );
      }
      return true;
    },
  });

  datadogLogs.init({
    clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN ?? '',
    env: process.env.REACT_APP_DD_RUM_ENV,
    forwardErrorsToLogs: true,
    service: process.env.REACT_APP_DD_RUM_APP_NAME,
    sessionSampleRate: Number(process.env.REACT_APP_DD_RUM_SAMPLE_RATE),
    site: 'datadoghq.com',
  });

  datadogRum.startSessionReplayRecording();
}
